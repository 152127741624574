<template>
    <div id="loading-screen" class="fixed top-0 left-0 w-screen h-screen bg-gray-900 delay-[600ms]">
        <div
            class="container flex flex-col items-center h-full mt-[calc((100vh-20rem)/2)] lg:mt-[calc((100vh-16rem)/2)]"
        >
            <video
                muted
                playsinline
                autoplay
                class="w-full max-w-[12rem] sm:max-w-[15rem] lg:max-w-[20rem] transition-opacity"
                :class="[
                    isLogoMounted ? 'opacity-100' : 'opacity-0',
                    isTextAnimationFinished ? 'duration-[750ms]' : 'duration-[1500ms]',
                ]"
                style="mix-blend-mode: lighten"
            >
                <source :src="require('~/assets/videos/intro.mp4')" type="video/mp4" />
            </video>

            <p class="text-body text-white text-center -mt-4">
                <Transition name="fade-up-slow">
                    <span v-if="areTextsMounted" :class="isTextAnimationFinished ? 'delay-[0ms]' : 'delay-[300ms]'">
                        An international, flexible & affordable
                    </span>
                </Transition>
                <br />
                <Transition name="fade-up-slow">
                    <span v-if="areTextsMounted" :class="isTextAnimationFinished ? 'delay-[100ms]' : 'delay-[600ms]'">
                        full-service (post) production house since 2005.
                    </span>
                </Transition>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen',
    data() {
        return {
            isLogoMounted: false,
            areTextsMounted: false,
            isTextAnimationFinished: false,
        };
    },
    mounted() {
        window.scrollTo(0, 0);

        // this.$disableBodyScroll('#loading-screen');

        setTimeout(() => {
            this.isLogoMounted = true;
            this.areTextsMounted = true;
        }, 100);

        setTimeout(() => {
            this.isTextAnimationFinished = true;

            // this.$enableBodyScroll('#loading-screen');

            this.$nextTick(() => {
                this.areTextsMounted = false;
                this.isLogoMounted = false;

                this.$nextTick(() => {
                    this.$store.dispatch('globals/hideLoadingScreen');
                    this.$store.commit('globals/setShouldPlayLoadingScreenAnimation', false);
                });
            });
        }, 3500);

        setTimeout(() => {
            this.$store.commit('cookie/setIsCookieBannerAllowedToBeVisible', true);
        }, 6000);
    },
};
</script>

<style scoped></style>
