export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
    async nuxtServerInit({ commit, dispatch }, { req }) {
        await dispatch('data/fetchFeaturedData');
    },
};
