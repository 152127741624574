export const state = () => ({
    scrollY: 0,
    windowWidth: 0,
    cursorPosition: null,
    isLoadingScreenVisible: true,
    shouldPlayLoadingScreenAnimation: true,
    scrollDirection: null,
});

export const getters = {};

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setCursorPosition(state, payload) {
        state.cursorPosition = payload;
    },
    setIsLoadingScreenVisible(state, payload) {
        state.isLoadingScreenVisible = payload;
    },
    setShouldPlayLoadingScreenAnimation(state, payload) {
        state.shouldPlayLoadingScreenAnimation = payload;
    },
    setScrollDirection(state, payload) {
        state.scrollDirection = payload;
    },
};

export const actions = {
    hideLoadingScreen({ commit }) {
        commit('setIsLoadingScreenVisible', false);
    },
    displayLoadingScreen({ commit }) {
        commit('setIsLoadingScreenVisible', true);
    },
};
