var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"fixed top-0 left-0 w-screen",class:[
        _vm.$store.state.globals.scrollDirection === 'down' &&
        !_vm.isMenuOverlayOpen &&
        _vm.$store.state.globals.scrollY > 50 &&
        (!_vm.$device.isDesktop || (_vm.$device.isDesktop && _vm.$store.state.globals.cursorPosition?.y > 200))
            ? 'lg:-translate-y-full'
            : ' lg:translate-y-0',
        _vm.$store.state.globals.scrollY > 110 && !_vm.$store.state.navigation.isMenuOverlayOpen
            ? 'bg-gray-900 '
            : 'bg-transparent ',
    ],staticStyle:{"transition":"background-color 0.3s, transform 0.3s"}},[_c('div',{staticClass:"flex items-center justify-between relative py-4 transition-all lg:py-6 px-4 lg:px-16 z-2",class:[_vm.isMenuOverlayOpen ? 'bg-transparent' : 'bg-gradient-to-b from-black/30']},[_c('Transition',{attrs:{"name":"fade"}},[_c('NuxtLink',{staticClass:"transition-all",class:!_vm.isMenuOverlayOpen || (_vm.isMenuOverlayOpen && _vm.navigationScrollTop < 100)
                        ? 'opacity-100 pointer-events-all'
                        : 'opacity-0 -translate-y-4 pointer-events-none',attrs:{"to":"/","aria-label":"Homepage"},nativeOn:{"click":function($event){return _vm.$store.commit('navigation/setIsMenuOverlayOpen', false)}}},[_c('GyarLogo')],1)],1),_vm._v(" "),_c('div',{staticClass:"inline-flex items-center"},[_c('Button',{staticClass:"p-button-info hidden lg:inline-flex mr-12",class:{ 'opacity-0 pointer-events-none': _vm.isMenuOverlayOpen },attrs:{"to":"/contact","label":"Say Hi!"}}),_vm._v(" "),_c('AnimatingHamburgerButton',{attrs:{"active":_vm.isMenuOverlayOpen},nativeOn:{"click":function($event){return _vm.toggleMenuOverlay.apply(null, arguments)}}})],1)],1),_vm._v(" "),_c('Transition',{attrs:{"name":"fade-header-navigation"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuOverlayOpen),expression:"isMenuOverlayOpen"}],staticClass:"fixed top-0 left-0 w-full z-1 bg-gray-900/50"},[_c('div',{staticClass:"overflow-auto pt-12 lg:pt-24 2xl:pt-12 pb-12 h-screen",attrs:{"id":"nav-scroll-container"},on:{"scroll":_vm.onNavigationScroll}},[_c('Navigation',{attrs:{"target":"header"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }