<template>
  <div class="relative overflow-hidden h-[4rem] xl:h-[6rem] 2xl:h-[6rem] 3xl:h-[7rem]" :class="{ active }">
    <video autoplay loop muted playsinline class="w-full h-full object-cover transition-all duration-[500ms]">
      <source :src="videoSrc" type="video/mp4"/>
    </video>

    <div
        class="absolute w-full h-full top-0 left-0 bg-white transition-all duration-[500ms]"
        :class="active ? 'opacity-0' : 'opacity-100'"
    ></div>

    <NuxtLink :to="url">
      <svg class="absolute top-0 left-0 w-full" style="height: calc(100% + 1px)">
        <mask :id="`${text}-mask`">
          <rect fill="white" width="100%" height="100%"></rect>
          <text class="jumbo" dominant-baseline="central" x="50%" y="50%" text-anchor="middle">
            {{ text }}
          </text>
        </mask>

        <rect
            width="100%"
            height="100%"
            fill="#10121A"
            :style="`mask: url('#${text}-mask')`"
        ></rect>
      </svg>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: 'TextWithVideoBackground',
  props: {
    text: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    videoSrc: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
};
</script>

<style scoped>
video {
  filter: blur(20px);
}

.active video {
  filter: blur(0);
}
</style>
