<template>
    <footer class="py-24 lg:pt-24 lg:pb-8">
        <div class="container mb-20">
            <NewsletterForm v-if="$route.path !== '/newsletter'" as-card />
        </div>

        <Navigation target="footer" />
    </footer>
</template>

<script>
import Navigation from '@/components/Layout/Navigation.vue';
import NewsletterForm from '@/components/Forms/NewsletterForm.vue';

export default {
    name: 'Footer',
    components: {
        Navigation,
        NewsletterForm,
    },
};
</script>

<style scoped></style>
