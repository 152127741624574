import Vue from 'vue';
import 'primeicons/primeicons.css';

import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';

Vue.use(PrimeVue);

Vue.component('InputText', InputText);
Vue.component('Textarea', Textarea);
Vue.component('Checkbox', Checkbox);
Vue.component('Dropdown', Dropdown);
