<template>
    <component :is="tag" class="header-1 dot-gif-anim inline-block">
        <span
            v-if="withDot && !centered"
            class="w-6 h-6 lg:w-8 lg:h-8 2xl:w-10 2xl:h-10 inline-block mr-1 rounded-full bg-cover bg-center translate-y-0.5 lg:translate-y-1"
            :class="dotClass"
        ></span>
        {{ text }}
    </component>
</template>

<script>
export default {
    name: 'Title',
    props: {
        text: {
            type: String,
            required: true,
        },
        withDot: {
            type: Boolean,
            required: false,
            default: false,
        },
        centered: {
            type: Boolean,
            required: false,
            default: false,
        },
        dotClass: {
            type: String,
            required: false,
            default: '',
        },
        tag: {
            type: String,
            required: false,
            default: 'h2',
        },
    },
};
</script>

<style scoped>
.dot-gif-anim:hover span {
    @apply bg-black;
    background-image: url('@/assets/images/gifs/gyar-dot-anim.gif');
}
</style>
