<template>
    <header
        class="fixed top-0 left-0 w-screen"
        :class="[
            $store.state.globals.scrollDirection === 'down' &&
            !isMenuOverlayOpen &&
            $store.state.globals.scrollY > 50 &&
            (!$device.isDesktop || ($device.isDesktop && $store.state.globals.cursorPosition?.y > 200))
                ? 'lg:-translate-y-full'
                : ' lg:translate-y-0',
            $store.state.globals.scrollY > 110 && !$store.state.navigation.isMenuOverlayOpen
                ? 'bg-gray-900 '
                : 'bg-transparent ',
        ]"
        style="transition: background-color 0.3s, transform 0.3s"
    >
        <div
            class="flex items-center justify-between relative py-4 transition-all lg:py-6 px-4 lg:px-16 z-2"
            :class="[isMenuOverlayOpen ? 'bg-transparent' : 'bg-gradient-to-b from-black/30']"
        >
            <Transition name="fade">
                <NuxtLink
                    to="/"
                    aria-label="Homepage"
                    class="transition-all"
                    :class="
                        !isMenuOverlayOpen || (isMenuOverlayOpen && navigationScrollTop < 100)
                            ? 'opacity-100 pointer-events-all'
                            : 'opacity-0 -translate-y-4 pointer-events-none'
                    "
                    @click.native="$store.commit('navigation/setIsMenuOverlayOpen', false)"
                >
                    <GyarLogo />
                </NuxtLink>
            </Transition>

            <div class="inline-flex items-center">
                <Button
                    to="/contact"
                    class="p-button-info hidden lg:inline-flex mr-12"
                    label="Say Hi!"
                    :class="{ 'opacity-0 pointer-events-none': isMenuOverlayOpen }"
                >
                </Button>
                <AnimatingHamburgerButton :active="isMenuOverlayOpen" @click.native="toggleMenuOverlay" />
            </div>
        </div>

        <Transition name="fade-header-navigation">
            <div v-show="isMenuOverlayOpen" class="fixed top-0 left-0 w-full z-1 bg-gray-900/50">
                <div
                    id="nav-scroll-container"
                    class="overflow-auto pt-12 lg:pt-24 2xl:pt-12 pb-12 h-screen"
                    @scroll="onNavigationScroll"
                >
                    <Navigation target="header" />
                </div>
            </div>
        </Transition>
    </header>
</template>

<script>
import GyarLogo from '@/components/Logos/GyarLogo.vue';
import AnimatingHamburgerButton from '@/components/UI/AnimatingHamburgerButton.vue';
import Navigation from '@/components/Layout/Navigation.vue';

export default {
    name: 'Header',
    components: {
        GyarLogo,
        AnimatingHamburgerButton,
        Navigation,
    },
    data() {
        return {
            navigationScrollTop: 0,
            arePanelsToggleable: true,
        };
    },
    computed: {
        isMenuOverlayOpen() {
            return this.$store.state.navigation.isMenuOverlayOpen;
        },
    },
    watch: {
        isMenuOverlayOpen(value) {
            if (value) {
                this.$disableBodyScroll('#nav-scroll-container');
            } else {
                this.$enableBodyScroll('#nav-scroll-container');
            }
        },
    },
    mounted() {
        window.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                this.$store.commit('navigation/setIsMenuOverlayOpen', false);
            }
        });
    },
    methods: {
        onNavigationScroll(e) {
            this.navigationScrollTop = e.target.scrollTop;
        },
        toggleMenuOverlay() {
            if (!this.arePanelsToggleable) {
                return;
            }

            this.$store.commit('navigation/setIsMenuOverlayOpen', !this.isMenuOverlayOpen);

            this.cooDown();
        },
        cooDown() {
            this.arePanelsToggleable = false;
            setTimeout(() => {
                this.arePanelsToggleable = true;
            }, 500);
        },
    },
};
</script>

<style scoped></style>
