export const state = () => ({
    featuredWork: null,
    featuredWorks: [],
});

export const mutations = {
    setFeaturedWork(state, payload) {
        state.featuredWork = payload;
    },
    setFeaturedWorks(state, payload) {
        state.featuredWorks = payload;
    },
};

export const actions = {
    fetchFeaturedData({ commit }) {
        return this.$axios
            .$get('/frontpage')
            .then((res) => {
                if (res.success === 1) {
                    commit('setFeaturedWork', res.data.featured);
                    commit('setFeaturedWorks', res.data.works);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
