<template>
    <div class="flex">
        <a
            v-for="icon in icons"
            :key="icon.name"
            :href="icon.url"
            target="_blank"
            class="social-icon"
            :class="light ? 'border-gray-100' : 'border-[#5A5A5A]'"
            :aria-label="icon.ariaLabel"
        >
            <span class="" v-lazy-background :lazy-background="icon.img"></span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'SocialIcon',
    props: {
        light: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            icons: [
                {
                    name: 'YouTube',
                    img: require('~/assets/images/icons/YouTube.svg'),
                    ariaLabel: 'Visit our YouTube channel',
                    url: 'https://www.youtube.com/channel/UCuqqOUcyuw5HEeBaklRqYiA',
                },
                {
                    name: 'Vimeo',
                    img: require('~/assets/images/icons/Vimeo.svg'),
                    ariaLabel: 'Visit our Vimeo channel',
                    url: 'https://vimeo.com/gyar',
                },
                {
                    name: 'Behance',
                    img: require('~/assets/images/icons/Behance.svg'),
                    ariaLabel: 'Visit our Behance portfolio',
                    url: 'https://www.behance.net/gyarpostproduction',
                },
                {
                    name: 'Facebook',
                    img: require('~/assets/images/icons/Facebook.svg'),
                    ariaLabel: 'Visit our Facebook page',
                    url: 'https://www.facebook.com/gyarpostproduction',
                },
                {
                    name: 'LinkedIn',
                    img: require('~/assets/images/icons/Linkedin.svg'),
                    ariaLabel: 'Visit our LinkedIn page',
                    url: 'https://www.linkedin.com/company/gyar-post-production',
                },
            ],
        };
    },
};
</script>

<style scoped>
.social-icon {
    @apply inline-block rounded-full w-10 h-10 sm:w-12 sm:h-12 relative border mr-4 last:mr-0 hover:border-white transition-all;
    span {
        @apply inline-block w-3 h-3 sm:w-4 sm:h-4 bg-contain bg-center bg-no-repeat absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
}
</style>
