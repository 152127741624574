<template>
    <a
        v-if="to && target === '_blank'"
        :href="to"
        target="_blank"
        rel="noopener noreferrer"
        class="p-button p-component"
    >
        <span v-if="icon" :class="`p-button-icon ${icon} p-button-icon-${iconPos}`"></span>
        <span class="p-button-label">
            {{ label }}
        </span>
    </a>
    <NuxtLink v-else-if="to" :to="to" class="p-button p-component">
        <span v-if="icon" :class="`p-button-icon ${icon} p-button-icon-${iconPos}`"></span>
        <span class="p-button-label">
            {{ label }}
        </span>
    </NuxtLink>
    <Button v-else :label="label" :disabled="disabled" :icon="icon" :icon-pos="iconPos" />
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'CustomButton',
    components: {
        Button,
    },
    props: {
        to: {
            type: [String, Object],
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        target: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        iconPos: {
            type: String,
            required: false,
            default: 'right',
        },
    },
};
</script>
