export default ({ app, $config, route, req }) => {
    // Find out host url from x-forwarded-host header (must be configured in the proxy server properly)
    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'];
    } else {
        host = window.location.origin;
    }

    // Add meta tags
    const meta = [];

    meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${$config.baseURL}/og-img.gif`,
    });

    meta.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${$config.baseURL}/og-img.gif`,
    });

    meta.push({
        property: 'og:image:type',
        content: 'image/gif',
    });

    meta.push({
        property: 'og:url',
        content: $config.baseURL + route.fullPath,
    });

    meta.push({
        name: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    app.head.meta.push(...meta);
};
