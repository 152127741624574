export default function ({ redirect, route }) {
    // eslint-disable-next-line
    if (process.server && route.fullPath === '/works') {
        return redirect('/selected-works');
    }

    // eslint-disable-next-line
    if (process.server && route.fullPath === '/jobs') {
        return redirect('/career');
    }
}
