import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6856dee0 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _6bcfc98a = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _a1699398 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _9ed1099a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _412e6876 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _7abe0d54 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _00421050 = () => interopDefault(import('../pages/selected-works/index.vue' /* webpackChunkName: "pages/selected-works/index" */))
const _2f4e3edb = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _78076246 = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _37dca86e = () => interopDefault(import('../pages/selected-works/_work.vue' /* webpackChunkName: "pages/selected-works/_work" */))
const _d41adcf8 = () => interopDefault(import('../pages/team/_member.vue' /* webpackChunkName: "pages/team/_member" */))
const _efe28cb6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6856dee0,
    name: "about"
  }, {
    path: "/career",
    component: _6bcfc98a,
    name: "career",
    children: [{
      path: ":position?",
      component: _a1699398,
      name: "career-position"
    }]
  }, {
    path: "/contact",
    component: _9ed1099a,
    name: "contact"
  }, {
    path: "/newsletter",
    component: _412e6876,
    name: "newsletter"
  }, {
    path: "/privacy-policy",
    component: _7abe0d54,
    name: "privacy-policy"
  }, {
    path: "/selected-works",
    component: _00421050,
    name: "selected-works"
  }, {
    path: "/services",
    component: _2f4e3edb,
    name: "services"
  }, {
    path: "/team",
    component: _78076246,
    name: "team"
  }, {
    path: "/selected-works/:work?",
    component: _37dca86e,
    name: "selected-works-work"
  }, {
    path: "/team/:member",
    component: _d41adcf8,
    name: "team-member"
  }, {
    path: "/",
    component: _efe28cb6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
