export default function ({ $axios, $config }) {
    $axios.interceptors.request.use((request) => {
        return request;
    });

    // $axios.interceptors.response.use((response) => {
    //     return response;
    // });

    $axios.onError((error) => {
        console.log('response error!');
        console.log(error.response.status);
    });

    $axios.defaults.baseURL = $config.apiBaseURL + '/api/';
}
