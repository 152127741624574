<template>
    <button v-show="isVisible" class="hamburger-icon" :class="{ active }" aria-label="Open menu">
        <span></span>
        <span></span>
        <span></span>
    </button>
</template>

<script>
export default {
    name: 'AnimatingSearchButton',
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isVisible: false,
        };
    },
    mounted() {
        this.isVisible = true;
    },
};
</script>

<style>
.hamburger-icon,
.hamburger-icon *::before,
.hamburger-icon *::after {
    --animating-hamburger-button-width: 16px;
    --animating-hamburger-button-ease-function: cubic-bezier(0.5, 0, 0, 1);
    --animating-hamburger-button-color: #fff;
    --animating-hamburger-button-thickness: 2px;
}

.hamburger-icon {
    display: inline-block;
    position: relative;
    width: var(--animating-hamburger-button-width);
    height: var(--animating-hamburger-button-width);

    min-width: 20px;
    margin: 0 calc(var(--animating-hamburger-button-width) / 2);

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    span {
        background: var(--animating-hamburger-button-color);
        position: absolute;
        height: var(--animating-hamburger-button-thickness);
        left: 0;
        right: 0;

        &:nth-of-type(1) {
            top: 0;
            transform-origin: left;
            transition: transform 0.3s var(--animating-hamburger-button-ease-function);
        }

        &:nth-of-type(2) {
            top: 50%;
            transform: translate3d(0, -50%, 0);
            transform-origin: center;
            background: none;

            &::before {
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--animating-hamburger-button-color);
                transform-origin: right;
                transition: transform 0.5s var(--animating-hamburger-button-ease-function) 0.15s;

                /* position */
                position: absolute !important;
            }
        }

        &:nth-of-type(3) {
            bottom: 0;
            transform-origin: left;
            transition: transform 0.5s var(--animating-hamburger-button-ease-function);
            transform: scale3d(0.75, 1, 1);
        }
    }

    &.active {
        span:nth-of-type(1) {
            transform: translate3d(15%, 0, 0) rotate(45deg);
            transition: transform 0.4s var(--animating-hamburger-button-ease-function) 0.2s;
        }

        span:nth-of-type(2) {
            transform: translate3d(0, -50%, 0) rotate(180deg);

            &::before {
                transform: translate3d(20%, 0, 0) scale3d(0, 1, 1);
            }
        }

        span:nth-of-type(3) {
            transform: translate3d(15%, 0, 0) rotate(-45deg);
            transition: transform 0.4s var(--animating-hamburger-button-ease-function) 0.25s;
        }
    }

    &:not(.active):hover span:nth-of-type(3) {
        transform: scale3d(1, 1, 1);
    }
}
</style>
